import React from "react";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

function getMenu(props = {}) {

    const {appContext, context} = props;
    const {routes} = appContext;
    const utils = getUtils(context);

    async function onClickBefore() {
        if (appContext.template?.actions){
            appContext.template.actions.storeDrawerScrollTop();
        }
        if (appContext.template?.actions){
            await appContext.template.actions.drawerCloseNarrow();
        }
    }

    const footerMenu = utils.getGlobalState("res.responses.footerMenu");

    return [
        ...(footerMenu && footerMenu.items.map((post)=>{
            return {
                name: post.title,
                href: routes.documentRoute+"/"+post.slug,
                featured: true,
                onClickBefore
            }
        })) || [],
    ];

}

export default getMenu;
