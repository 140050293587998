import React, {useContext} from "react";
import style from "./style.css";

import {WappContext} from "wapplr-react/dist/common/Wapp";
import ExternalContext from 'flatchooser-components/dist/common/components/App/externalContext'
import clsx from 'clsx'

export default function Logo(props) {

    const {wapp} = React.useContext(WappContext);

    const { className } = props;

    wapp.styles.use(style);

    return (
        <div className={clsx(style.logo,
            {
                [style[className]] : className && !!(style[className]),
                [className] : className && !(style[className])
            }
        )}>
            <div className={style.icon} >
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
                    <defs>
                        <clipPath id="a994d27a-44fb-40d4-970e-f467037d47d3">
                            <circle cx="50" cy="50" r="50" style={{"fill":"none"}}/>
                        </clipPath>
                    </defs>
                    <g style={{"clipPath":"url(#a994d27a-44fb-40d4-970e-f467037d47d3)"}}>
                        <circle cx="50" cy="50" r="50" style={{"fill": "#426dfb"}}/>
                        <rect x="-24.68" y="25.47" width="54.4" height="1.47" transform="translate(-12.76 4.77) rotate(-30)" style={{"fill":"#fff"}}/>
                        <rect x="-15.15" y="55.98" width="86.67" height="1.56" transform="translate(-26.86 88.15) rotate(-93.29)" style={{"fill":"#fff"}}/>
                        <rect x="71.33" y="73.38" width="29.67" height="1.51" transform="translate(14.64 162.48) rotate(-91.72)" style={{"fill":"#fff"}}/>
                        <path d="M27.86,43.52c-.57-10.29-1.15-20.8-1.74-31.35a3,3,0,0,1,2,.85L38.75,21.2,80.1,53c1.13.87,2.29,1.71,3.4,2.61a9.54,9.54,0,0,1,3.63,8.75c-.18,2.3,0,4.63,0,7,0,1.28,0,2.56,0,3.84s-.65,1.77-1.87,1.15c-1.71-.86-3.38-1.82-5-2.78L30,44.46A20.08,20.08,0,0,0,27.86,43.52Z" style={{"fill":"#51a179","opacity":1}}/>
                        <path d="M28.31,15.7q1.82,1.38,3.61,2.78l5.6,4.32L76.18,52.51l2.69,2.07,1.22.93c.75.56,1.46,1.09,2.15,1.65a7.58,7.58,0,0,1,2.89,7,48.87,48.87,0,0,0-.06,5c0,.71,0,1.42,0,2.12v1.18c0,.52,0,1,0,1.51-1.36-.72-2.72-1.5-3.94-2.21L60.85,60Q45.94,51.38,31,42.74a8.43,8.43,0,0,0-1.22-.58L28.31,15.7m-2.07-3.52h-.13c.59,10.54,1.18,21.05,1.75,31.34a22,22,0,0,1,2.15.94q25.1,14.51,50.16,29c1.66,1,3.33,1.93,5,2.79a2.16,2.16,0,0,0,.94.28c.59,0,.88-.48.92-1.44.06-1.27,0-2.56,0-3.84,0-2.32-.15-4.64,0-7A9.6,9.6,0,0,0,83.5,55.6c-1.11-.89-2.27-1.73-3.41-2.6L38.74,21.21,28.14,13a3,3,0,0,0-1.9-.86Z" style={{"fill":"#6fefb0"}}/>
                    </g>
                    <g id="fa2be548-ef44-4d49-a354-bf392f7fe3d4" data-name="cursor">
                        <path d="M40.52,65v2.83H37.87V70.2H32.6V24.66h2.55v2.58H38v2.64h2.48v2.66h2.82v2.67H46v2.63h2.63v2.82h2.67v2.67H54.1V46h2.67v2.64h2.66v2.85h2.64v2.67H64.9v5.66H54.21v2.48h2.56V67.6h2.64v5.48H56.84v2.6H51.33V73.15H48.72l-.31-5.29H46v-5.5H43.39V65h-2.9V62.25h2.7a20.44,20.44,0,0,1,0-2.09c0-.22.3-.58.49-.6.74-.07,1.49,0,2.35,0v2.75h2.62v5.29c.38,0,.66,0,.93,0,1.87,0,1.87,0,1.87,1.92V73h5.1V67.74H53.93V62.47c-.71,0-1.34,0-2,0-.24,0-.64-.28-.65-.44,0-1.66,0-3.31,0-5H62V54.28c-.65,0-1.25,0-1.84,0s-.94-.16-.88-.84,0-1.18,0-1.85c-.76,0-1.39,0-2,0-.48,0-.68-.15-.66-.65,0-.68,0-1.36,0-2.13H53.93V46.2H51.22V43.51H48.54V40.73H45.78V38.06H43.13V35.38H40.41V32.71c-.67,0-1.24,0-1.8,0s-1-.17-.93-.9c0-.55,0-1.11,0-1.66H35.3v37.4h2.54V65Z" style={{"fill":"#4f4f4f"}}/>
                        <path d="M43,64.61v2.83H40.37v2.41H35.1V24.32h2.55V26.9h2.86v2.63H43V32.2h2.82v2.66h2.67V37.5h2.63v2.82h2.67V43H56.6v2.67h2.67v2.63h2.66v2.86h2.64V53.8H67.4v5.67H56.71v2.47h2.56v5.32h2.64v5.47H59.34v2.61H53.83V72.8H51.22l-.31-5.29H48.52V62H45.89v2.62H43V61.9h2.7a20.48,20.48,0,0,1,0-2.09c0-.22.3-.58.49-.6.74-.07,1.49,0,2.35,0v2.74h2.62v5.3c.38,0,.66,0,.93,0,1.87,0,1.87,0,1.87,1.92v3.46h5.1V67.39H56.43V62.13a18.08,18.08,0,0,1-2,0c-.23,0-.63-.28-.64-.43,0-1.66,0-3.32,0-5.06H64.53V53.94c-.65,0-1.25,0-1.84,0s-.94-.16-.88-.85,0-1.17,0-1.85c-.76,0-1.39,0-2,0-.48,0-.68-.16-.66-.66,0-.68,0-1.36,0-2.13H56.42V45.85h-2.7V43.16H51V40.39H48.28V37.72H45.63V35H42.91V32.37c-.67,0-1.24,0-1.81,0s-1-.17-.92-.9c0-.55,0-1.11,0-1.65H37.8V67.21h2.54v-2.6Z"/>
                        <path d="M43,64.61H40.34v2.6H37.8V29.82h2.39c0,.54,0,1.1,0,1.65-.07.73.24,1,.92.9s1.14,0,1.81,0V35h2.72v2.69h2.65v2.67H51v2.77h2.68v2.69h2.7v2.62h2.73c0,.77,0,1.45,0,2.13,0,.5.18.68.66.66.63,0,1.26,0,2,0,0,.68,0,1.27,0,1.85s.23.89.88.85,1.19,0,1.84,0v2.67H53.79c0,1.74,0,3.4,0,5.06,0,.15.41.41.64.43a18.08,18.08,0,0,0,2,0v5.26h2.64v5.26H54V69.19c0-1.91,0-1.91-1.87-1.92-.27,0-.55,0-.93,0v-5.3H48.55V59.19c-.86,0-1.61,0-2.35,0-.19,0-.47.38-.49.6a20.48,20.48,0,0,0,0,2.09H43v2.74Z" style={{"fill":"#fff"}}/>
                    </g>
                </svg>
            </div>
        </div>
    )
}


export function FC(props) {

    const {wapp} = useContext(WappContext);

    const {className} = props;

    wapp.styles.use(style);

    return (
        <div className={clsx(style.logo,
            {
                [style[className]] : className && !!(style[className]),
                [className] : className && !(style[className])
            }
        )}>
            <div className={style.icon} >
                <svg xmlns="http://www.w3.org/2000/svg" fill={"currentColor"} viewBox="0 0 141.19 20">
                    <path d="M9.73,14.85H7.19v6.88H2.5V2.28h7.66a8.51,8.51,0,0,1,5.41,1.54,5.18,5.18,0,0,1,1.95,4.33,6.4,6.4,0,0,1-.82,3.37,5.83,5.83,0,0,1-2.58,2.16l4.06,7.85v.2h-5Zm-2.54-3.6h3a2.66,2.66,0,0,0,2-.71,2.71,2.71,0,0,0,.67-2,2.78,2.78,0,0,0-.68-2,2.65,2.65,0,0,0-2-.71h-3Z" transform="translate(-2.5 -2)"/>
                    <path d="M32.41,13.48H25v4.65h8.71v3.6H20.35V2.28H33.78V5.9H25V10h7.37Z" transform="translate(-2.5 -2)"/>
                    <path d="M41.82,2.28l4.34,13.53L50.49,2.28h6.17V21.73H52V17.19l.45-9.3-4.7,13.84h-3.1L39.89,7.88l.46,9.31v4.54H35.66V2.28Z" transform="translate(-2.5 -2)"/>
                    <path d="M70.93,18.1H64.5l-1.12,3.63h-5L65.51,2.28h4.4L77.1,21.73h-5Zm-5.3-3.62h4.18l-2.1-6.75Z" transform="translate(-2.5 -2)"/>
                    <path d="M92.19,5.9H86.35V21.73H81.66V5.9H75.93V2.28H92.19Z" transform="translate(-2.5 -2)"/>
                    <path d="M106.23,13.48H98.85v4.65h8.71v3.6H94.16V2.28h13.43V5.9H98.85V10h7.38Z" transform="translate(-2.5 -2)"/>
                    <path d="M125.21,15.15a7,7,0,0,1-1.09,3.58,6.57,6.57,0,0,1-2.81,2.41,9.46,9.46,0,0,1-4.14.86,7.62,7.62,0,0,1-6-2.5q-2.2-2.49-2.2-7v-1a11.85,11.85,0,0,1,1-5,7.56,7.56,0,0,1,2.87-3.31A8,8,0,0,1,117.12,2a8.4,8.4,0,0,1,5.69,1.86A7.38,7.38,0,0,1,125.25,9h-4.67a3.52,3.52,0,0,0-.9-2.55,3.65,3.65,0,0,0-2.56-.78A2.81,2.81,0,0,0,114.55,7a8.41,8.41,0,0,0-.85,4.18v1.38a9.32,9.32,0,0,0,.78,4.45,2.86,2.86,0,0,0,2.69,1.34,3.58,3.58,0,0,0,2.47-.77,3.22,3.22,0,0,0,.91-2.41Z" transform="translate(-2.5 -2)"/>
                    <path d="M143.69,21.73H139V13.61h-6.91v8.12h-4.69V2.28h4.69V10H139V2.28h4.66Z" transform="translate(-2.5 -2)"/>
                </svg>
            </div>
        </div>
    )

}

export function LogoFC(props) {

    const {wapp} = useContext(WappContext);

    const {className} = props;

    wapp.styles.use(style);

    return (
        <div className={clsx(style.logoContainer,
            {
                [style[className]] : className && !!(style[className]),
                [className] : className && !(style[className])
            }
        )}>
            <Logo />
            <FC />
        </div>
    )
}
