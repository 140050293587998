import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "player";
    const N = capitalize(name);
    const Ns = N+"s";

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["embedPage"+N+"Label"]: "Embed",
            ["backFromWide"+N+"EntryLabel"]: "Back",
            ["download"+N+"Label"]: "Download",
        },
        menus: {
            ...menus,
            ["new"+N+"Menu"]: "Create an player",
            ["new"+N+"MenuFlatchooserApp3"]: "Create [flatchooser-app-3]",
            ["new"+N+"MenuSoldiagramApp"]: "Create [soldiagram-app]",
            ["new"+N+"MenuBoligKonfiguratorApp"]: "Create [boligkonfigurator-app]",
            ["more"+Ns+"Menu"]: "More players",
        },
        messages: {
            ...messages,
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
        }
    }
}
