import React, {useContext, useEffect, useState, useRef} from 'react';

import {WappContext, withWapp} from 'wapplr-react/dist/common/Wapp';
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils';
import clsx from 'clsx';

import {withMaterialStyles} from '../../../components/Template/withMaterial';
import PostContext from '../../../components/Post/context';
import ExternalContext from '../../../components/App/externalContext';
import AppContext from '../../../components/App/context';

import materialStyle from './materialStyle';
import style from './style.css';

function Content(props) {

    const context = useContext(WappContext);
    const postContext = useContext(PostContext);
    const appContext = useContext(AppContext);
    const {name = "player", post} = postContext;

    const {wapp, req, res} = context;
    const utils = getUtils(context);

    const {routes} = appContext;

    wapp.styles.use(style);

    const {
        subscribe,
        //materialStyle,
        justContent,
        externalSettings = {},
    } = props;

    const playerApp = props.playerApp || res.wappResponse[name+"App"];

    const {store, appStateName = "APP_STATE"} = playerApp?.res?.wappResponse || {};
    const state = store ? store.getState() : {};
    const stateText = `if (!window["${appStateName}"] || window["${appStateName}"] && !window["${appStateName}"].req){ window["${appStateName}"] = ${JSON.stringify(state)} }`;
    const containerElementId = playerApp?.wapp.getTargetObject().config.containerElementId;

    const {params} = res.wappResponse.route;
    const innerPath = "/"+"/:page/:innerPage1/:innerPage2/:innerPage3".split("/:").filter((key)=>params[key]).map((key)=>params[key]).join("/");
    const parentPath = req.wappRequest.path.split(innerPath)[0];

    const container = useRef();

    const [Player, setPlayer] = useState(playerApp?.Render);
    const [url, setUrl] = useState(utils.getRequestUrl());

    wapp.styles.use({
        _getCss: function () {
            if (wapp.target === "node") {
                const css = playerApp?.res ? playerApp.wapp.styles.getCssText() : "";
                return (css[0] && css[0].cssText) || "";
            }
            return "";
        }
    });

    useEffect(function () {
        const unsub = subscribe.locationChange(function onLocationChange(newUrl) {

            if (newUrl !== url) {

                const playerApp = props.playerApp || res.wappResponse[name+"App"];

                if (playerApp?.res) {

                    if (playerApp.update && !playerApp.updated) {
                        playerApp.update();
                        playerApp.updated = true;
                    }

                    if (playerApp.Render && playerApp.Render !== Player) {
                        setPlayer(playerApp.Render)
                    }

                }

                setUrl(newUrl);

            }

        });
        return function useUnsubscribe(){
            unsub();
        }
    }, [url])

    useEffect(()=>{
        setPlayer(playerApp.Render)
    }, [containerElementId])

    useEffect(()=>{
        if (wapp.target === "web") {
            const playerApp = props.playerApp || res.wappResponse[name+"App"];
            if (playerApp?.res) {
                playerApp.res.container = container.current;
                playerApp.res.wappResponse.container = container.current;
            }
        }
    }, [container])

    if (!playerApp?.res || playerApp?.res?.wappResponse?.statusCode === 404){
        res.wappResponse.status(404)
        if (!playerApp?.res){
            return null;
        }
    }

    return (
        <ExternalContext.Provider value={{externalSettings: {
                id: containerElementId,
                ...externalSettings,
                parentUrl: req.wappRequest.protocol+"://"+req.wappRequest.hostname + parentPath,
                playerUrl: req.wappRequest.protocol+"://"+req.wappRequest.hostname + routes[name+"Route"]+"/"+post?._id,
                embedUrl:  req.wappRequest.protocol+"://"+req.wappRequest.hostname + routes[name+"Route"]+"/embed/"+post?._id,
            }}}>
            {
                (justContent && Player) ?
                    <div
                        ref={container}
                        className={clsx({[style.heightByParent]: externalSettings.heightByParent})}
                        id={containerElementId}>
                        {(Player) ? Player : null}
                        <script dangerouslySetInnerHTML={{__html: stateText}}/>
                    </div>
                    :
                    <div className={clsx(style.post, {[style.heightByParent]: externalSettings.heightByParent})}>
                        <div
                            ref={container}
                            className={clsx(style.content, {[style.heightByParent]: externalSettings.heightByParent})}
                            id={containerElementId}>
                            {(Player) ? Player : null}
                            <script dangerouslySetInnerHTML={{__html: stateText}}/>
                        </div>
                    </div>
            }
        </ExternalContext.Provider>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
