import {runPostTypesConfigSync} from "../../postTypes";

const titles = {
    homeTitle: "Home",
    acceptCookiesTitle: "Accept cookies",
    featuredTitle: "Featured Players",
    featuredSubtitle: "Featured items of the collection",
    ...runPostTypesConfigSync({action:"getConstants", rKey:"titles"}).reduce((a, v) => {return {...a, ...v}}, {})
};

export default titles;
