import React from "react";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import getDefaultMenu from "../../components/Post/menu";
import capitalize from "../../utils/capitalize";

export default function getMenu(p) {

    const {
        appPath = "",
        embedIsFeatured = false,
        embedEnableUser = false,
        appContext,
        statusManager,
        name = "player",
        ...rest
    } = p;

    const N = capitalize(name);

    const embedMenu = {
        href:(p) => "/embed/"+p.post?._id + appPath,
        name: appContext.labels["embedPage"+N+"Label"],
        Icon: OpenInFullIcon,
        role: function role(p) {
            const isAdmin = p.user && p.user[appContext.userStatusManager._status_isFeatured];
            const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
            const isBanned = p.post && p.post[statusManager._status_isBanned];
            if ((isBanned && !isAdmin)){
                return false;
            }
            if (isAdmin || isAuthor) {
                return !!(p.post?._id && p.page !== "new" && p.page !== "edit");
            }
            return (embedEnableUser) ? p.post && p.post[statusManager._status_isNotDeleted] : false
        },
        featured: embedIsFeatured,
        onlyIcon: true
    }

    return [
        embedMenu,
        ...getDefaultMenu({name, appContext, statusManager, ...rest}).map((m)=>{delete m.featured; return m;}),
    ]
}
