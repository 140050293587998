import React, {useContext, useState, useEffect, useMemo} from "react";
import CloseIcon from "@mui/icons-material/Close";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext} from "wapplr-react/dist/common/Wapp";

import AppContext from "../../../components/App/context";
import PostContext from "../../../components/Post/context";
import capitalize from "../../../utils/capitalize";

import Content from "../Content";
import style from "./style.css";

export default function EmbedComponent(props){

    const {appContext = {}, name="player", subscribe, userPostTypeName = "user"} = props;
    const N = capitalize(name);

    const context = useContext(WappContext);

    const utils = getUtils(context);

    const {wapp, res} = context;

    wapp.styles.use(style);

    const [url, setUrl] = useState(utils.getRequestUrl());
    const [user, setUser] = useState(utils.getRequestUser());
    const lastUrl = useMemo(()=>wapp.client?.history.lastUrl || "", [url, user])

    useEffect(function (){

        function onUserChange(user){
            setUser((user?._id) ? user : null);
        }

        const unsub = subscribe.userChange(onUserChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, user]);

    useEffect(function (){

        function onLocationChange(newUrl){
            if (url !== newUrl){
                setUrl(newUrl);
            }
        }

        const unsub = subscribe.locationChange(onLocationChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, url]);

    const responses = utils.getGlobalState().res.responses;

    const wappResponse = res.wappResponse;
    const route = wappResponse.route;
    const {params} = route;
    const {_id} = params;

    const slugPattern = /^[a-zA-Z0-9](-?[a-zA-Z0-9]){1,150}$/;
    const objectIdPattern = /^[0-9a-fA-F]{24}$/;

    const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern);
    const requestName = _id?.match(objectIdPattern) ? name+"FindById" : isSlugRequest ? name+"FindBySlug" : name+"FindById";
    const post = (responses && responses[requestName]) ||
        (isSlugRequest && responses && responses[name+"FindById"] && responses[name+"FindById"].slug === _id && responses[name+"FindById"]) ||
        (!isSlugRequest && responses && responses[name+"FindBySlug"] && responses[name+"FindBySlug"]._id === _id && responses[name+"FindBySlug"]);

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: userPostTypeName}).statusManager;

    return (
        <AppContext.Provider value={{userStatusManager, ...appContext}}>
            <PostContext.Provider value={{user, post}}>
                <Content
                    {...props}
                    justContent={true}
                    externalSettings={{
                        position:"fixed",
                        fullscreen: true,
                        disableLogo: false,
                        getTopMenu: (p)=> {

                            const page = p.context.res.wappResponse.route.requestPath || "";
                            const href = lastUrl ? lastUrl : (post) ? appContext.routes[name + "Route"] + "/" + post._id + page : appContext.routes[name + "Route"];

                            return [
                                {
                                name: appContext.labels["backFromWide"+N+"Label"],
                                onClick: async (e) => {
                                    e.preventDefault();
                                    await p.appContext.template.actions.drawerClose();
                                    wapp.client.history.push(href)
                                },
                                disableParentRoute: true,
                                Icon: CloseIcon,
                                featured: true,
                                onlyIcon: true,
                                href,
                            }]
                        }
                    }}
                />
            </PostContext.Provider>
        </AppContext.Provider>
    )
}
