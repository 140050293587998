// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KpdWb {}\n\n.QIsVb {\n    margin-bottom: 32px;\n}\n\n.keGjp {}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"new": "KpdWb",
	"content": "QIsVb",
	"form": "keGjp"
};
module.exports = ___CSS_LOADER_EXPORT___;
