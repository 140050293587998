// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Daib7 {\n    height: 100%;\n    width: auto;\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    color: var(--secondary-bg);\n}\n\n@media (prefers-color-scheme: dark) {\n    .Daib7{\n        color: var(--secondary-color);\n    }\n}\n\n.Daib7.iNHxg {\n    color: var(--font-color);\n}\n\n@media (prefers-color-scheme: dark) {\n    .Daib7.iNHxg {\n        color: var(--font-color);\n    }\n}\n\n.Daib7 .YHiZM {\n    height: 90%;\n    width: auto;\n}\n\n.Daib7 svg , .Daib7 .sj9Tl {\n    height: 100%;\n    width: auto;\n    display: -ms-flexbox;\n    display: flex;\n    max-width: 100%;\n}\n\n._2O4w6 {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-wrap: nowrap;\n        flex-wrap: nowrap;\n    height: 100%;\n    padding: 6px;\n}\n\n._2O4w6 > div:nth-child(2){\n    margin-left: 8px;\n    -ms-flex-item-align: center;\n        align-self: center;\n    width: -webkit-max-content;\n    width: -moz-max-content;\n    width: max-content;\n    max-height: 24px;\n}\n\n._2O4w6 > div:first-child {\n    aspect-ratio: 1/1;\n}\n\n@media (max-width:799px) {\n    ._2O4w6 {\n        max-width: 160px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "Daib7",
	"listItem": "iNHxg",
	"icon": "YHiZM",
	"svg": "sj9Tl",
	"logoContainer": "_2O4w6"
};
module.exports = ___CSS_LOADER_EXPORT___;
