import React, {useContext, useEffect, useRef, useState} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../../components/App/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";
import PostContext from "../../../components/Post/context";
import Form, {defaultComponents} from "../../../components/Form";
import SchemaForm from "../../../components/SchemaForm";

import materialStyle from "./materialStyle";
import style from "./style.css";

import getSchemaForSchemaForm from "../Edit/schema";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../index";
import Content from "../Content";
import getApp from "../getApp";

function getFormDataForParentForm({post, utils, name, appContext}) {

    const N = capitalize(name);

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation."+name+"New.formData");
    } catch (e){}

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels["new"+N+"SubmitLabel"]
        }
    };

    if (formData["record.title"]) {
        formData["record.title"].hidden = true;
        formData["record.title"].disabled = true;
    }

    if (post?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = post;
                ka.forEach(function (nk) {
                    if (nk !== "record"){
                        if ((value && value[nk]) || (value && value[nk] === 0)){
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0){
                    formData[key].value = value;
                }
            }
        })
    }

    return formData;

}

function NewWithoutPost() {

    const postContext = useContext(PostContext);
    const {post, name, parentRoute} = postContext;

    const N = capitalize(name);

    const context = useContext(WappContext);
    const {wapp, res} = context;
    const appContext = useContext(AppContext);
    const utils = getUtils(context);

    wapp.styles.use(style);

    const schemaFormForm = useRef();
    const form = useRef();

    if (!post._id){
        post._id = "new"
    }

    let postContent = {};
    try {
        postContent = JSON.parse(post.content)
    } catch (e){}

    const appSlug = res.wappResponse.route.params.appSlug || "flatchooser-app-3";

    const formSettingsForParent = getFormDataForParentForm({post, utils, name, appContext});

    const [schemaForSchemaForm, _setSchemaForm] = useState(null);
    useEffect(()=>{
        async function setSchemaForm() {
            const newSchemaForSchemaForm = await getSchemaForSchemaForm({data: postContent, appSlug});
            await _setSchemaForm(newSchemaForSchemaForm)
        }
        setSchemaForm();
    }, [])

    if (!schemaForSchemaForm){
        return null;
    }

    formSettingsForParent["record.content"].componentName = "SchemaForm";
    formSettingsForParent["record.content"].data = postContent;
    formSettingsForParent["record.content"].schema = schemaForSchemaForm;
    formSettingsForParent["record.appSlug"].value = appSlug;

    Object.keys(formSettingsForParent).forEach((key)=>{
        if ((formSettingsForParent[key].componentName?.startsWith("Posts") && formSettingsForParent[key].enableNew) ||
            (formSettingsForParent[key].schemaType === "MongoID" && formSettingsForParent[key].enableNew) ){
            const refPostType = formSettingsForParent[key].refPostType;
            formSettingsForParent[key].NewComponent = postTypesConfig[refPostType] && postTypesConfig[refPostType].getPages ? postTypesConfig[refPostType].getPages().new : null;
        }
    });

    async function onSubmit(e, data) {

        const isValid = schemaFormForm.current.isValid();
        const schemaFieldResponse = await schemaFormForm.current.onSubmit(e);
        const schemaFieldData = schemaFieldResponse.data;
        const schemaFieldError = schemaFieldResponse.error;

        if (schemaFieldError || !isValid) {
            return;
        }

        const languages = schemaFieldData.settings?.basic_settings?.languages || ["en_us"];

        const args = {
            ...data,
            record: {
                ...data.record,
                content: JSON.stringify({
                    ...schemaFieldData
                }),
                title: (schemaFieldData.settings?.basic_settings?.name && schemaFieldData.settings?.basic_settings?.name[languages[0]]) ||
                    (schemaFieldData.settings?.basic_settings?.title && schemaFieldData.settings?.basic_settings?.title[languages[0]]) ||
                    (schemaFieldData.props?.settings?.props?.settings && schemaFieldData.props.settings.props.settings[0] && schemaFieldData.props.settings.props.settings[0].title) ||
                    data.record.title || "My player"
            }
        }

        return await utils.sendRequest({requestName: name+"New", args, redirect: {pathname: parentRoute+"/:_id", search:"", hash:""}, timeOut:1000 });

    }

    const editorActions = {
        actions: {
            setData: async ()=>{}
        }
    };

    const customFormComponents = {
        SchemaForm: {
            Component: SchemaForm,
            props: {
                schema: {},
                data: {},
                ref: (e)=>{schemaFormForm.current = e;},
                storageName: (post?._id) ? "SchemaForm_"+post._id : "SchemaForm",
                FormComponent: (props)=> <div className={props.className}>{props.children}</div>,
                onSubmit: async (e, {data, error})=>{
                    return {data, error};
                },
                additionalProperties: {
                    generateSchema: ({schemaFormContext}) => {
                        return getSchemaForSchemaForm({data: schemaFormContext.rootData});
                    },
                    updateEditor: async (p)=>{
                        await editorActions.actions.setData(p.schemaFormContext.rootData);
                    }
                }
            }
        },
    }

    return (
        <div className={style.new}>
            <div className={style.content}>
                <PostContext.Provider value={{...postContext, post: {...post}}}>
                    <Content externalSettings={{
                        onDataChange: async function (newData) {
                            const formData = form.current.state.formData;
                            const newDataValue = JSON.stringify(newData);
                            if (formData["record.content"].value !== newDataValue) {
                                formData["record.content"] = {
                                    ...formData["record.content"],
                                    value: newDataValue,
                                    data: newData
                                }
                                await form.current.setNewFormData(formData);
                            }
                        },
                        effect: function ({actions}) {
                            editorActions.actions = actions;
                        }
                    }}/>
                </PostContext.Provider>
            </div>
            <div className={style.form}>
                <Form
                    ref={form}
                    formData={formSettingsForParent}
                    onSubmit={onSubmit}
                    successMessage={
                        appContext.messages["new"+N+"SuccessMessage"]
                    }
                    components={{
                        ...defaultComponents,
                        ...customFormComponents
                    }}
                    initialState={(state) =>{
                        return {
                            ...state,
                            formData: {
                                ...state.formData,
                                "record.content": {
                                    ...state.formData["record.content"],
                                    schema: schemaForSchemaForm
                                }
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}

function New(props) {
    const context = useContext(WappContext);
    const postContext = useContext(PostContext);

    const {res} = context;
    const appSlug = res.wappResponse.route.params.appSlug || "flatchooser-app-3";

    const [defaultAppData, _setDefaultAppData] = useState(null);

    async function setDefaultAppData() {
        const copyDefaultAppData = JSON.parse(JSON.stringify((await getApp(appSlug)).defaultAppData));
        await _setDefaultAppData(copyDefaultAppData);
    }

    useEffect(()=>{
        setDefaultAppData();
    }, [])

    if (!defaultAppData){
        return null;
    }

    return (
        <PostContext.Provider value={{...postContext, post: {title: "My player", content: JSON.stringify(defaultAppData)}}}>
            <NewWithoutPost {...props}/>
        </PostContext.Provider>
    )
}

const WappComponent = withWapp(New);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
