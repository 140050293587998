export default async function getApp(appSlug) {

    let createChildWapp
    let defaultAppData
    let schema

    switch(appSlug) {
        case "flatchooser-app-3":
            createChildWapp = (await import("flatchooser-app-3")).createChildWapp
            defaultAppData = (await import("flatchooser-app-3/dist/common/defaultAppData")).default
            schema = (await import("flatchooser-app-3/dist/common/schema")).default
            break;
        case "soldiagram-app":
            createChildWapp = (await import("soldiagram-app")).createChildWapp
            defaultAppData = (await import("soldiagram-app/dist/common/defaultAppData")).default
            schema = (await import("soldiagram-app/dist/common/schema")).default
            break;
        case "boligkonfigurator-app":
            createChildWapp = (await import("boligkonfigurator-app")).createChildWapp
            defaultAppData = (await import("boligkonfigurator-app/dist/common/defaultAppData")).default
            schema = (await import("boligkonfigurator-app/dist/common/schema")).default
            break;
        default:
            createChildWapp = (await import("flatchooser-app-3")).default
            defaultAppData = (await import("flatchooser-app-3/dist/common/defaultAppData")).default
            schema = (await import("flatchooser-app-3/dist/common/schema")).default
    }

    return {
        createChildWapp,
        defaultAppData,
        schema,
    }
}
