// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CzuBT {\n\n}\n\n.\\+31x9 {\n    margin-bottom: 32px;\n}\n\n.mMKK7 {}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit": "CzuBT",
	"content": "+31x9",
	"form": "mMKK7"
};
module.exports = ___CSS_LOADER_EXPORT___;
