import React, {useContext, useEffect, useState, useRef, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import Button from "@mui/material/Button";
import {copyObject} from "wapplr/dist/common/utils";

import clsx from "clsx";

import {withMaterialStyles} from "../Template/withMaterial";
import AppContext from "../App/context";

import Posts from "../Posts";
import Logo, {FC} from "../Logo";

import DocumentContent from "../../postTypes/document/Content";
import PostContext from "../../components/Post/context";

import getMenu from "../../postTypes/player/getMenu";
import getApp from "../../postTypes/player/getApp";

import PlayerContent from "../../postTypes/player/Content";

import materialStyle from "./materialStyle";
import style from "./style.css";

function Player({player}) {

    const context = useContext(WappContext);

    const {wapp, req} = context;

    const appSlug = player.appSlug;

    const _id = player._id;
    const url = "/";
    const queryString = "";

    const [playerApp, _setPlayerApp] = useState(null);

    async function setPlayerApp() {
        if (!playerApp || (playerApp && playerApp.wapp.globals.NAME !== appSlug)) {
            const {createChildWapp} = await getApp(appSlug);
            const newPlayerApp = await createChildWapp({
                parentWapp: wapp,
                parentReq: req,
                parentRoute: "/",
                config: {globals: {WAPP: wapp.globals.WAPP + "_" + appSlug + "_" + _id}},
                req: {
                    path: url,
                    url: (queryString) ? url + "?" + queryString : url
                },
                beforeRun: ({wapp})=>{
                    wapp.client.history.disableParentWappPush = true;
                }
            });
            await _setPlayerApp(newPlayerApp)
        }
    }

    useEffect(() => {
        setPlayerApp()
    }, [appSlug])

    return playerApp ?
        <PostContext.Provider value={{post: player}}>
            <PlayerContent
                playerApp={playerApp}
                justContent={true}
                externalSettings={{
                    heightByParent: true,
                    summaryConfigurationUrl: function ({queryString, externalSettings}){
                        return externalSettings.embedUrl + queryString
                    }
                }}
            />
        </PostContext.Provider>
        :
        null

}

function ColorSchemes(props) {

    const {style, onChange} = props;

    const [colorSchemeClass, setColorSchemeClass] = useState("");

    const colorSchemes = ["forceLight", "forceDark", "customColor"]

    async function onClick(e, colorScheme) {
        e.preventDefault();
        const newColorSchemeClass = (colorSchemeClass === colorScheme) ? "" : colorScheme;
        await setColorSchemeClass(newColorSchemeClass)
        onChange(newColorSchemeClass)
    }

    return (
        <div className={style.sectionColors}>
            {colorSchemes.map((colorScheme, i)=>{
                return <div
                    key={i}
                    onClick={(e)=>onClick(e, colorScheme)}
                    className={clsx(style[colorScheme+"Button"], {
                        [style.sectionColorsSelected] : colorScheme === colorSchemeClass
                    })}
                />
            })}
        </div>
    )
}

function Section(props) {

    const {player, style, reverseStyle} = props;
    const mockupContentContainer = useRef();

    return (
        <div className={clsx(style.section, {[style.sectionReverse]: reverseStyle})} >
            <div className={style.sectionInner}>
                <div className={style.sectionDescriptionContainer}>
                    <div className={style.sectionTitle}>{
                        (player.appSlug === "flatchooser-app-3") ? "BOLIGVELGER" :
                            (player.appSlug === "soldiagram-app") ? "SOLDIAGRAM" :
                                (player.appSlug === "boligkonfigurator-app") ? "BOLIGKONFIGURATOR" :
                                ""
                    }</div>
                    <div className={style.sectionDescription}>{
                        (player.appSlug === "flatchooser-app-3") ?
                            "Med denne webapplikasjonen kan du presentere leilighetene i bygningen, laste opp fotogallerier og plantegninger, og alle parameterne til leiligheten. Kjøper kan enkelt bla og søke blant leilighetene. De kan sende en forespørsel til investoren. Alle våre applikasjoner kan bygges inn på nettstedet ditt"
                            :
                            (player.appSlug === "soldiagram-app") ?
                                "Med denne applikasjonen kan du vise kundene dine på hvilken tid på dagen hvor mye lys den mottar bygningen eller leilighetene." :
                                (player.appSlug === "boligkonfigurator-app") ?
                                    "Med denne applikasjonen gir du kundene dine muligheten til å velge utstyr til leiligheten, de kan kjøpe ekstra tilbehør og beregne en pris." :
                                    ""
                    }</div>
                    <ColorSchemes style={style} onChange={(colorSchemeClass)=>{
                        if (mockupContentContainer.current) {
                            mockupContentContainer.current.className = clsx(
                                style.mobileMockupContent,
                                { [style[colorSchemeClass]]: colorSchemeClass && style[colorSchemeClass] }
                            )
                        }
                    }}/>
                </div>
                <div className={style.mobileMockup}>
                    <div className={style.mobileMockupBackground}/>
                    <div
                        ref={mockupContentContainer}
                        className={style.mobileMockupContent}
                    >
                        <Player player={player} />
                    </div>
                </div>
            </div>
        </div>
    )
}

function Sections(props) {

    const {featured = [], style} = props;

    return (
        <>
            {featured?.length ? featured.map((player, i) =>
                    <Section
                        key={i}
                        player={player}
                        style={style}
                        reverseStyle={!((i+1) % 2)}
                    />
                )
                : null
            }
        </>
    )
}

function Home(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {wapp} = context;
    const utils = getUtils(context);

    const {subscribe} = props;

    wapp.styles.use(style);

    const [user, setUser] = useState(utils.getRequestUser());

    function onUserChange(user){
        setUser((user?._id) ? user : null);
    }

    useEffect(function didMount(){
        const unsub = subscribe.userChange(onUserChange);
        return function willUnmount() {
            unsub();
        }
    }, [subscribe, user]);

    async function onClick(e, menu) {

        e.preventDefault();

        const href = menu.href;

        if (menu.href === "#featured"){
            window.scrollTo({behavior: "smooth", top: document.getElementById("featured").offsetTop-64-48})
        } else {
            wapp.client.history.push({
                search:"",
                hash:"",
                ...wapp.client.history.parsePath(href)
            });
        }

    }

    const container = useRef();

    const homeFeatured = useMemo(()=>utils.getGlobalState("res.responses.homeFeatured"), []);
    const featured = copyObject(homeFeatured?.items);

    return (
        <div className={style.home} >
            <div id={"home"} className={style.first} ref={container}>
                <div className={style.firstBg} />
                <div className={style.firstCenter}>
                    <div className={style.firstIcon}>
                        <Logo />
                    </div>
                    <div className={style.firstLogo}>
                        <FC />
                    </div>
                    <div className={style.firstDesc}>{wapp.getTargetObject().config.description}</div>
                </div>
            </div>
            <Sections featured={featured} style={style} />
        </div>
    )
}

const WappComponent = withWapp(Home);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
